<template>
  <div class='page-box'>
    <div class='tr' style='margin-bottom:12px;'>
      <a-button type='primary' icon='plus' @click='addVoucher'>新增凭证</a-button>
    </div>
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.shopVoucherId" :loading="loadingRef" @change="handleTableChange">
      <template slot="state" slot-scope="text, record">
        <span v-if="record.state === 0" style="color:#ff9400">审核中</span>
        <span v-if="record.state === 1" style='color:#52c41a'>审核通过</span>
        <span v-if="record.state === -1" style='color:#ff4d4f'>审核被拒绝</span>
      </template>
      <div class='table-operations ' slot="operation" slot-scope="text, record">
        <a-button type='link' @click='editVoucher(record)' :disabled='record.state !== -1'>重新提交</a-button>
      </div>
    </base-table>
    <a-modal width='800px' :title="manageVoucherId?'编辑':'新增凭证'" v-model="showModal" :confirm-loading="confirmLoading" @ok="handleSubmit" :okText='manageVoucherId?"重新提交":"提交"'>
      <div class='flex-row'>
        <div class='tree-box'>
          <a-tree :expandedKeys="expandedKeys" @expand="expandedKeys = arguments[0]" :auto-expand-parent="true" :selectedKeys='selectedKeys' :replaceFields="{children:'children', title:'name', key:'manageVoucherId' }" :tree-data="treeData" @select="onSelect" />
        </div>
        <div class='right-content'>
          <div v-if='selectedNode.manageVoucherId'><span style='color:#000;font-weight:900'>要求：</span>{{selectedNode.content}}</div>
          <div v-if='selectedNode.manageVoucherId' style='margin-top: 16px;'>
            <div><span style='color:#000;font-weight:900'>所包含具体商品类目：</span></div>
            <div v-for='item in productVoucherList' :key='item.productCategoryId'>{{item.productCategoryName}}</div>
            <div v-if='selectedNode.manageVoucherId' style='margin-top: 16px;'>
              <div style="margin-bottom: 16px;"><span style='color:#000;font-weight:900'>凭证图片：</span></div>
              <picture-card-upload :fileList="voucherImgs" @update:fileList='res=>voucherImgs=res' :max="9" />
            </div>
          </div>
          <div v-if='!selectedNode.manageVoucherId' class='not-select'>
            <span>请先选择经营类目</span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from '@vue/composition-api'
import { shop } from '@/api'
import { useTableList } from '@/hooks'
export default {
  name: 'ShopAddress',
  setup (props, { root }) {
    const state = reactive({
      showModal: false,
      confirmLoading: false,
      treeData: [],
      manageVoucherId: '',
      expandedKeys: [],
      selectedKeys: [],
      selectedNode: {},
      voucherImgs: [],
      productVoucherList: []
    })
    const columns = [
      {
        title: '经营类目名称',
        dataIndex: 'manageVoucherName'
      },
      {
        title: '提交时间',
        dataIndex: 'createTime'
      },
      {
        title: '审核时间',
        dataIndex: 'checkTime'
      },
      {
        title: '审核状态',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' }
      },
      {
        title: '操作',
        width: 200,
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      }
    ]
    const { dataRef, pageRtv, loadingRef, setPage, refresh } = useTableList(shop.getVoucher)
    function handleTableChange ({ current }) {
      setPage(current)
    }
    function addVoucher () {
      state.selectedNode = {}
      state.selectedKeys = []
      state.voucherImgs = []
      state.manageVoucherId = ''
      state.showModal = true
    }
    function editVoucher (record) {
      state.selectedNode = {
        manageVoucherId: record.manageVoucherId,
        content: record.content
      }
      state.manageVoucherId = record.manageVoucherId
      state.selectedKeys = [record.manageVoucherId]
      getProductVoucherList()
      getVoucherImgs()
      state.showModal = true
    }
    onMounted(async () => {
      await getVoucherTree()
      if (root.$route.query.id) {
        state.selectedKeys = [root.$route.query.id]

        let arr = []
        state.treeData.forEach((item) => {
          if (item.children && item.children.length) {
            arr.push(...item.children)
          }
        })
        state.selectedNode = arr.find((x) => x.manageVoucherId === root.$route.query.id)
          ? arr.find((x) => x.manageVoucherId === root.$route.query.id)
          : {
            manageVoucherId: root.$route.query.id
          }
        getProductVoucherList()
        getVoucherImgs()
        state.showModal = true
      }
    })
    async function handleSubmit () {
      if (state.voucherImgs.length === 0) return root.$message.error('请添加凭证图片')
      state.confirmLoading = true
      const { msg, code } = await shop.submitVoucherImgs({
        manageVoucherId: state.selectedNode.manageVoucherId,
        manageVoucherImg: state.voucherImgs.join(',')
      })
      state.confirmLoading = false
      if (code === '00000') {
        state.showModal = false
        root.$message.success('提交成功，请留意审核结果')
        refresh()
      } else {
        root.$message.error(msg || '提交失败')
      }
    }
    function onSelect (res, event) {
      if (event.node.$options.propsData.dataRef.manageVoucherPid === '0') return
      state.selectedNode = event.node.$options.propsData.dataRef
      state.selectedKeys = [state.selectedNode.manageVoucherId]
      getProductVoucherList()
      getVoucherImgs()
    }
    async function getVoucherTree () {
      const { data, code } = await shop.getVoucherTree()
      if (code === '00000') {
        state.treeData = data
        state.expandedKeys = data[0] && data[0].manageVoucherId ? [data[0].manageVoucherId] : []
      }
    }
    async function getVoucherImgs () {
      const { data, code } = await shop.getVoucherImg({
        manageVoucherId: state.selectedNode.manageVoucherId
      })
      if (code === '00000') {
        state.voucherImgs = data ? data.split(',') : []
      }
    }
    async function getProductVoucherList () {
      const { data, code } = await shop.getProductVoucherList({
        manageVoucherPid: state.selectedNode.manageVoucherId
      })
      if (code === '00000') {
        state.productVoucherList = data
      }
    }
    return {
      handleSubmit,
      getProductVoucherList,
      onSelect,
      getVoucherTree,
      columns,
      dataRef,
      pageRtv,
      editVoucher,
      loadingRef,
      setPage,
      refresh,
      addVoucher,
      ...toRefs(state),
      handleTableChange
    }
  }
}
</script>
<style lang="less" scoped>
.flex-row {
  display: flex;
  .tree-box {
    padding-right: 32px;
    border-right: 1px solid #ddd;
  }
  .right-content {
    flex: 1;
    padding-left: 32px;
  }
}
.not-select {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
